:root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --text-primary: #78378c;
  --text-secondary: #12ba93;
}

body {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
} */

body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  background-color: #1a1d1d;
}

body::-webkit-scrollbar-thumb {
  background-color: #78378c;
}

.navbar {
  background-color: #12ba93;
  background: linear-gradient(130deg, #12ba93 0, #78378c 100%);
  /* max-height: 0.1vh; */
  display: flex;
  position: fixed;
  width: 15rem;
  height: 100vh;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(20px + 2vmin);
  color: white;
  padding: 0;
  z-index: 1;
}

.nav-items {
  margin-top: 1vh;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(130deg, #78378c 0, #12ba93 100%);
  font-size: calc(20px + 2vmin);
  color: white;
  height: 100%;
  margin-left: 10rem;
}

.body:last-child {
  padding-bottom: 100px;
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: space-evenly;
  padding: 80px;
  margin-left: 10rem;
  margin: 25px;
  /* border: 5px solid rgba(154, 177, 175, 0.5); */
  background-color: rgba(154, 177, 175, 0.3);
  border-radius: 10px;
  width: 35%;
  position: relative;
}

#hi {
  background-color: rgba(154, 177, 175, 0);
  padding: 0;
  font-size: 3rem;
  font-weight: 250;
  width: auto;
  /* display: block; */
  max-width: 590px;
}

.project-image {
  border-radius: 10px;
  width: 30vw;
}

.project-image:focus {
  outline: none;
}

.description {
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

p {
  display: flex;
  margin: 15px;
  overflow-wrap: normal;
  align-self: center;
}

h3 {
  display: flex;
  margin: 15px;
  overflow-wrap: normal;
  align-self: center;
  font-size: 1.5rem;
  -webkit-font-smoothing: antialiased;
  font-weight: 250;
}

a {
  align-self: center;
  text-decoration: none;
  color: white;
  display: flex;
  flex-direction: column;
}

a:hover {
  color: #12ba93;
}

.project-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30vw;
}

button {
  color: white;
  text-decoration: none;
  cursor: pointer;
  margin: 10px;
  padding: 8px 24px;
  height: auto;
  font-size: 1rem;
  border-radius: 5px;
  font-weight: 650;
  background: none;
  border: 1px solid white;
  transition: 0.2s;
}

button:hover {
  color: white;
  background: #12ba93;
  border-color: #12ba93;
  text-decoration: none;
  cursor: pointer;
  margin: 10px;
}

button:focus {
  outline: none;
}

#portfolioImage {
  max-height: 10rem;
  padding-top: 15px;
}

a {
  margin-bottom: 5px;
}

.headerLinks {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  font-size: calc(5px + 2vmin);
  margin: 0;
}

.aboutLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#email {
  margin: calc(10px + 2vmin);
  max-height: calc(15px + 2vmin);
}
#github {
  margin: calc(10px + 2vmin);
  max-height: calc(15px + 2vmin);
  align-self: center;
  margin-bottom: 5px;
}

#linkedin {
  margin: calc(10px + 2vmin);
  margin-top: 5px;
  max-height: calc(15px + 2vmin);
}

#github-body {
  max-height: calc(15px + 2vmin);
  margin: 15px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.react-pdf__Page__canvas {
  height: 80%;
}

#resume {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: rgba(154, 177, 175, 0.8);
  color: #505ca0;
  justify-content: center;
  z-index: 1;
  /* height: auto; */
  padding: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
